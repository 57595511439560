<!-- 模块说明 -->
<template>
  <div class="container">
    <subHead :active="3"></subHead>
    <div class="banner">
      <div class="title">
        <h5>海恒动态</h5>
        <p>Haiheng Dynamics</p>
      </div>
    </div>
    <div class="crumbs">
      <div class="content">
        当前位置：<span @click="$router.push('/')">网站首页</span> >
        <span @click="jumpTo('/trends?show=0')">海恒动态</span> > 公司新闻详情
      </div>
    </div>
    <div class="news" v-if="hotspotList.length > 0">
      <div class="content">
        <div class="left">
          <div class="tiem">
            <div class="yy">
              {{ Dateformat(hotspotList[show].releaseTime, "YYYY") }}年
            </div>
            <p></p>
            <div class="mm">
              {{ Dateformat(hotspotList[show].releaseTime, "MM") }}
              <span>月</span>
            </div>
          </div>
          <div class="operate">
            <div v-if="show !== 0" @click="reduce" class="view">
              <i class="el-icon-arrow-up"></i>
              <span>上一篇</span>
            </div>
            <div class="view" @click="jumpTo('/trends?show=0')">
              <i class="el-icon-menu"></i>
              <span>返回列表</span>
            </div>
            <div v-if="hotspotList.length > 1" @click="add" class="view">
              <i class="el-icon-arrow-down"></i>
              <span>下一篇</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            {{ hotspotList[show].title }}
          </div>
          <div class="time">
            发布时间：{{
              Dateformat(hotspotList[show].releaseTime, "YYYY-MM-DD")
            }}
          </div>
          <div v-html="processedContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import subHead from "../../components/subHead.vue";
export default {
  components: { subHead },
  data() {
    return {
      show: 0,
      hotspotList: [],
    };
  },
  watch: {},
  created() {
    this.show = this.$route.query.index;
    this.hotspotList = JSON.parse(
      decodeURIComponent(this.$route.query.hotspotList)
    );
    console.log(this.hotspotList);
  },
  computed: {
    processedContent() {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.hotspotList[this.show].contentsOfArticle, "text/html");
      // 定义需要保留 Referrer 的关键词列表
      const keepReferrerPaths = [
        "szj-contract",
        "szj-active",
        "szj-asset",
        "szj-private",
        "szj-temporary",
      ];
      doc.querySelectorAll("a, img").forEach((element) => {
        console.log(element.src);
        const url = element.href || element.src; // 获取链接地址
        // 判断是否命中保留名单
        const shouldKeepReferrer = keepReferrerPaths.some((path) => {
          return url.includes(path); // URL 包含关键词则跳过处理
        });
        // 动态设置 referrerpolicy
        if (!shouldKeepReferrer) {
          element.setAttribute("referrerpolicy", "no-referrer");
        }
      });
      return doc.body.innerHTML;
    },
  },
  methods: {
    jumpTo(url) {
      this.$router.push(url);
    },
    reduce() {
      this.show--;
      if (this.show < 0) {
        this.show = 0;
        this.$message("当前已是首篇");
      }
    },
    add() {
      this.show++;
      if (this.hotspotList[this.show].enableTheExternalChain) {
        window.open(
          this.handelHttps(this.hotspotList[this.show].externalLinkUrl),
          "_blank"
        );
      }
      let i = this.hotspotList.length - 1;
      if (this.show > i) {
        this.show = i;
        this.$message("当前已是末篇");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.news {
  .content {
    .right {
      .view {
        // max-height: 700px;
        overflow-y: auto;
        padding-right: 5px;
      }
      img {
        width: 100%;
        display: block;
        margin-bottom: 40px;
      }
      .text {
        font-size: 16px;
        color: #333333;
        padding-bottom: 40px;
        text-indent: 40px;
        line-height: 30px;
      }
      .time {
        font-size: 16px;
        color: #999999;
        padding: 30px 0 40px;
      }
      .title {
        font-size: 28px;
        color: #333333;
        line-height: 38px;
      }
      max-width: 850px;
    }
    .left {
      .operate {
        .view:hover {
          font-weight: bold;
        }
        .view {
          margin-bottom: 40px;
          cursor: pointer;
        }
        span {
          padding-left: 15px;
        }
        i {
          background: #166dba;
          color: white;
          padding: 15px;
          border-radius: 50%;
        }
        font-size: 16px;
        color: #333333;
        margin-top: 63px;
      }
      .tiem {
        .mm {
          span {
            font-size: 25px;
            font-weight: 500;
            padding-left: 5px;
          }
          font-size: 30px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          width: 80px;
          height: 1px;
          background: #5da5e5;
          margin: 15px auto;
        }
        .yy {
          font-size: 16px;
          margin-top: 28px;
        }
        width: 135px;
        height: 145px;
        background: #166dba;
        text-align: center;
        color: white;
        overflow: hidden;
      }
      margin-right: 85px;
    }
    margin: 60px auto;
    display: flex;
  }
}
.crumbs {
  span {
    cursor: pointer;
  }
  background: #fbfbfb;
  padding: 28px 0;
  font-size: 14px;
  color: #999999;
}
.banner {
  .title {
    position: absolute;
    top: 50%;
    left: 26%;
    transform: translate(-50%, -50%);
    p {
      font-size: 24px;
    }
    h5 {
      font-size: 48px;
      margin-bottom: 10px;
    }
    color: white;
  }
  width: 100%;
  height: 460px;
  background: url("../../images/home/za/topBack.png") no-repeat;
  background-size: cover;
  background-position-x: 50%;
  position: relative;
}
.content {
  width: 1200px;
  margin: auto;
}
.container {
  overflow: hidden;
  width: 100%;
}
</style>
